import React, { useEffect, useRef } from 'react';
import { DataSet, Network } from 'vis-network/standalone';

const NetworkGraph = ({ nodes, edges }) => {
  const containerRef = useRef(null);
  const network = useRef(null);
  const colorList = ['red', 'blue', 'green', 'yellow', 'orange', 'purple', 'pink', 'brown', 'grey', 'black'];

  useEffect(() => {
    if (nodes && edges && containerRef.current) {


      if (network.current) {
        network.current.destroy();
      }
          // Create a DataSet with the nodes and edges
    const nodesDataSet = new DataSet();
    const edgesDataSet = new DataSet();

    nodesDataSet.clear();
    edgesDataSet.clear();

    nodesDataSet.add(nodes.map(node => ({
      ...node,
      label: node.label.replace(/[\s.]/g, '\n'),
      color: colorList[node.level % colorList.length],
      shape: 'dot',
    })));
    edgesDataSet.add(edges);

      const options = {
        layout: {
          hierarchical: {
            direction: 'UD', // Change this to 'DU', 'LR', or 'RL' as needed
            sortMethod: 'directed', // This ensures the lower levels are below higher levels
          },
        },
      };

      // Create a Network with the container, nodes, and edges
      network.current = new Network(containerRef.current, { nodes: nodesDataSet, edges: edgesDataSet }, options);

      // Add a selectNode event listener
      network.current.on('selectNode', function (params) {
        const nodeId = params.nodes[0]; // Get the id of the selected node

        // Change the color of the selected node and its connected nodes and edges
        nodesDataSet.get().forEach(node => {
          if (node.id === nodeId || network.current.getConnectedNodes(nodeId).includes(node.id)) {
            nodesDataSet.update({ id: node.id, color: colorList[node.level % colorList.length] });
          } else {
            nodesDataSet.update({ id: node.id, color: 'grey' });
          }
        });
        edgesDataSet.get().forEach(edge => {
          if (edge.from === nodeId || edge.to === nodeId) {
            edgesDataSet.update({ id: edge.id, color: colorList[nodesDataSet.get(edge.from).level % colorList.length] });
          } else {
            edgesDataSet.update({ id: edge.id, color: 'grey' });
          }
        });
      });

      // Add a deselectNode event listener
      network.current.on('deselectNode', function () {
        // Reset the color of all nodes and edges
        nodesDataSet.get().forEach(node => {
          nodesDataSet.update({ id: node.id, color: colorList[node.level % colorList.length] });
        });
        edgesDataSet.get().forEach(edge => {
          edgesDataSet.update({ id: edge.id, color: colorList[nodesDataSet.get(edge.from).level % colorList.length] });
        });
      });
    }
  }, [nodes, edges]);

  return <div ref={containerRef} style={{ height: '83vh', width: '100%' }} />;
};

export default NetworkGraph;