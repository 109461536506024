import React from 'react';
import logo from '../energy-pool-logo.png'
import './Navbar.css';
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { useLocation } from 'react-router-dom';




/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props 
 */
function Navbar(props) {

  const location = useLocation();

  // You can use the pathname to determine the current route
  let name;
  switch(location.pathname) {
    case '/complexsystems':
      name = 'Complex Systems';
      break;
    case '/flexdb':
      name = 'FLEX DB';
      break;
    case '/mvtool':
      name = 'M&V Tool';
      break;
    case '/jepx':
      name = 'JEPX Backtesting';
      break;
      default:
      name = 'Internal App Portal';
  }

  const isAuthenticated = useIsAuthenticated();

  return (
<nav>
<div onClick={(e) => {
      e.preventDefault();
      window.location='/';
      }}
       className="logo"><img src={logo} height="50px" alt=""></img></div>
<div className="app-name"><p>{name}</p></div>
<div className="links">
    <a href='https://forms.office.com/e/CyBSuJSpVs' target="_blank">Report a Bug </a>
    <a>|</a>
    <a href='https://forms.office.com/e/6X3rfrVTQm' target="_blank"> Contact </a>
    <a>|</a>
    { isAuthenticated ? <SignOutButton /> : <SignInButton /> }
</div>
</nav>
  );
}

export default Navbar;
