/**
 * 
 */

import "./styles/flexDB.css";
import {useMsal} from "@azure/msal-react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {Box, Button} from "@mui/material";
import {download, generateCsv, mkConfig} from "export-to-csv";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {ReactComponent as DotIcon} from "../../assets/icons/dot.svg";
import {ReactComponent as FolderIcon} from "../../assets/icons/folder-file-project.svg";
import {ReactComponent as FolderOpenIcon} from "../../assets/icons/folder-open.svg";
import {ReactComponent as TrashIcon} from "../../assets/icons/trash.svg";
import ReusableModal from "../../components/Modal";
import NetworkGraph from "../../components/NetworkGraph";
import ReusableSelect from "../../components/Select";


/**
 * FlexDB DOCSTRING
 **/

function FlexDB() {
  // #region USER CHECKS
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPro, setIsPro] = useState(false);
  const {accounts} = useMsal();
  const [userName, setUserName] = useState(accounts[0].idTokenClaims.name);
  const adminUsers = JSON.parse(process.env.REACT_APP_FLEX_DB_ADMIN_USERS);
  const proUsers = (JSON.parse(process.env.REACT_APP_FLEX_DB_PRO_USERS)).concat(adminUsers);

  useEffect(() => {
    if (adminUsers.includes(userName)) {
      console.log(userName + "Admin user detected");
      setIsAdmin(true);
    }
  }, [ userName ]);



  useEffect(() => {
    if (proUsers.includes(userName)) {
      console.log(userName + "Pro user detected");
      setIsPro(true);
    } else {
      console.log(userName + "Regular user detected");
    }
  }, [ userName ]);
  // #endregion
  // USER CHECKS

  // #region GET ABSTRACT CLASSES
  const [abstractClasses, setAbstractClasses] = useState([]);

  const getAbstractClasses = async () => {
    fetch(
        "https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/default/abstracts")
        .then((response) => response.json())
        .then((data) => setAbstractClasses(data))
        .then(() => getClasses())
        .catch((error) => console.log("Couldnt GET Abstract classes:", error));
  };

  useEffect(() => { getAbstractClasses(); }, []);

  // #endregion
  // GET ABSTRACT CLASSES

  // #region GET CLASSES
  const [classes, setClasses] = useState([]);

  const getClasses = async () => {
    fetch(
        "https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/default/class")
        .then((response) => response.json())
        .then((data) => setClasses(data))
        .then(() => getObjects())
        .catch((error) => console.log("Couldnt GET classes:", error));
  };

  // #endregion
  // GET CLASSES

  // #region GET OBJECTS
  const [objects, setObjects] = useState([]);

  // wait
  const getObjects = async () => {
    fetch(
        "https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/default/objects")
        .then((response) => response.json())
        .then((data) => setObjects(data))
        .catch((error) => console.log("Couldnt GET objects:", error));
  };

  // #endregion
  // GET OBJECTS

  // #region ADD ABSRACT CLASS
  const [isAbstractModalOpen, setIsAbstractModalOpen] =
      useState(false); // Modal for adding new abstract class
  const [abstractClassName, setAbstractClassName] = useState("");

  const addAbstractClass = async () => {
    const requestOptions = {
      method : "POST",
      headers : {"Content-Type" : "application/json"},
      body : JSON.stringify({name : abstractClassName}),
    };

    fetch(
        "https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/default/abstracts",
        requestOptions)
        .then((response) => response.json())
        .then((data) => alert(JSON.stringify(data)))
        .then(() => getAbstractClasses())
        .then(() => setAbstractClassName(""))
        .then(() => setIsAbstractModalOpen(false))
        .catch((error) => console.error("Error:", error));
  };
  // #endregion
  // ADD ABSRACT CLASS

  // #region ADD CLASS
  const [addClassModal, setaddClassModal] = useState(false); 
  const [className, setClassName] = useState("");
  const [properties, setProperties] = useState([
    {
      name : "",
      type : "",
      description : "",
      unit : "",
      required : "",
      enumValues : "",
    },
  ]);

  const addClass = async () => {
    const newArray = properties.map((property, index) => {
      if (property.type === "enum") {
        return {
          name : property.name,
          type : "string",
          description : property.description,
          unit : property.unit,
          required : property.required,
          enum : property.enumValues.split(",").map((value) => value.trim()),
        };
      } else if (property.type === "array") {
        return {
          name : selectedClassForLinkedObjects[index],
          type : "array",
          description : property.description,
          required : property.required,
        };
      } else {
        return {
          name : property.name,
          type : property.type,
          description : property.description,
          unit : property.unit,
          required : property.required,
        };
      }
    });

    let requiredNameProperty = {
      name : "_name",
      type : "string",
      description : "Name of the object",
      required : "y",
    };

    newArray.unshift(requiredNameProperty)
    // This makes sure that the name property is always first element and required

    const requestOptions = {
      method : "POST",
      headers : {"Content-Type" : "application/json"},
      body : JSON.stringify({
        name : className,
        properties : newArray, // could be tempArray for multiple entry
        abstractClasses : [ selectedClassForModal ],
      }),
    };

    console.log(requestOptions.body);

    fetch(
        "https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/default/class",
        requestOptions)
        .then((response) => response.json())
        .then((data) => alert(JSON.stringify(data.body)))
        .then(() => getClasses())
        .then(() => {
          setaddClassModal(false);
          // Reset the className and properties state variables
          setClassName("");
          setSelectedClass("");
          setProperties([
            {
              name : "",
              type : "",
              description : "",
              unit : "",
              required : "",
              enumValues : "",
            },
          ]);
        })
        .catch((error) => console.error("Error:", error));
  };

  const handlePropertyChange = (index, key, value) => {
    const newProperties = [...properties ];
    newProperties[index][key] = value;
    setProperties(newProperties);
  };

  const handleAddProperty = () => {
    setProperties([
      ...properties,
      {
        name : "",
        type : "",
        description : "",
        unit : "",
        required : "",
        enumValues : "",
      },
    ]);
  };

  const handleDeleteProperty = (index) => {
    const newProperties = [...properties ];
    newProperties.splice(index, 1);
    setProperties(newProperties);
  };
  // #endregion
  // ADD CLASS

  // #region ADD OBJECT
  const [addObjectModalOpen, setAddObjectModalOpen] =
      useState(false); // Modal for adding new object
  const [objectName, setObjectName] = useState("");
  const [selectedClass, setSelectedClass] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [selectedClassForLinkedObjects, setSelectedClassForLinkedObjects] =
      useState(null);
  const [linkedObjects, setLinkedObjects] = useState([]);

  const addObject = async () => {
    // Turn strings into numbers if the property type is 'number'
    for (let prop in inputValues) {
      if (selectedClass.jsonSchema.properties[prop] &&
          selectedClass.jsonSchema.properties[prop].type === "array") {
        inputValues[prop] = inputValues[prop].map((item) => ({
                                                    id : item.value._id,
                                                    label : item.label,
                                                  }));
      } else if (selectedClass.jsonSchema.properties[prop] &&
                 selectedClass.jsonSchema.properties[prop].type === "int") {
        console.log("Converting to number");
        inputValues[prop] = Number(inputValues[prop]);
      }
    }

    for (let prop of selectedClass.jsonSchema.required) {
      if (!inputValues[prop]) {
        alert(`Please fill the ${prop} field.`);
        return;
      }
    }

    const requestOptions = {
      method : "POST",
      headers : {"Content-Type" : "application/json"},
      body : JSON.stringify({
        className : selectedClass.name,
        data : {
          ...inputValues,
        },
      }),
    };

    fetch(
        "https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/default/objects",
        requestOptions)
        .then((response) => response.json())
        .then((data) => alert(JSON.stringify(data.body)))
        .then(() => {
          getObjects();
          setAddObjectModalOpen(false);
        })
        .catch((error) => alert("Error:", error));
  };

  const handleSelectChange = (event, prop) => {
    let value = "";
    if (selectedClass.jsonSchema.properties[prop].type === "array") {
      value = event;
      setLinkedObjects(
          (prevState) => [...prevState,
                          value]); // Add the selected object to linkedObjects
      console.log(linkedObjects);
    } else {
      value = event.target.value;
    }
    setInputValues((prevState) => ({...prevState, [prop] : value}));
  };

  const handleInputChange = (e, prop) => {
    let value = e.target.value;

    // Check if the input field is empty
    if (value === "" || value === null || value === undefined) {
      value = "-";
    }

    // Check if the property type is 'number' and convert the value to a number
    if (selectedClass.jsonSchema.properties[prop].type === "number") {
      console.log("Converting to number");
      value = Number(value);
    }

    setInputValues((prevState) => ({...prevState, [prop] : value}));
  };
  // #endregion
  // ADD OBJECT

  // #region ADD BULK OBJECTS
  const [csvInput, setCsvInput] = useState("");

  const handleCsvInput = (e) => {
    setCsvInput(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  const addBulkObjects = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "*/*");
    myHeaders.append("x-api-key", process.env.REACT_APP_FLEX_DB_API_KEY);

    const requestOptions = {
      method : "POST",
      headers : myHeaders,
      body : csvInput,
      redirect : "follow",
    };

    fetch(
        `https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/default/bulk_objects?className=${
            selectedClass.name}`,
        requestOptions)
        .then((response) => response.text())
        .then((result) => alert(JSON.parse(result).response))
        .then(() => getObjects())
        .then(() => setAddObjectModalOpen(false))
        .catch((error) => console.error(error));
  };

  // #endregion
  // ADD BULK OBJECTS

  // #region DELETE ABSTRACT CLASS
  // #endregion
  // DELETE ABSTRACT CLASS

  // #region DELETE CLASS

  const [selectedDeleteClass, setSelectedDeleteClass] = useState(null);

  const deleteClass = async () => {
    const requestOptions = {
      method : "DELETE",
      headers : {"Content-Type" : "application/json"},
      body : JSON.stringify({
        name : selectedDeleteClass.name,
      }),
    };

    fetch(
        "https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/default/class",
        requestOptions)
        .then((response) => response.json())
        .then((data) => alert(JSON.stringify(data.body)))
        .then(() => getClasses())
        .then(() => getObjects())
        .then(() => setSelectedDeleteClass(null))
        .then(() => setSelectedClass(null))
        .catch((error) => console.error("Error:", error));
  };

  useEffect(() => {
    if (selectedDeleteClass) {
      if (window.confirm("Are you sure you want to delete " +
                         selectedDeleteClass.name + " class?")) {
        deleteClass();
      } else {
        setSelectedDeleteClass(null);
      }
    }
  }, [ selectedDeleteClass ]);
  // #endregion
  // DELETE CLASS

  // #region DELETE OBJECT

  async function deleteObject(id) {
    if (window.confirm("Are you sure you want to delete this object?")) {
      console.log(id);

      const response = await fetch(
          `https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/default/objects`,
          {
            method : "DELETE",
            headers : {
              "Content-Type" : "application/json",
            },
            body : JSON.stringify({id : id}),
          });

      if (!response.ok) {
        alert("Couldnt delete object:", response.statusText);
      } else {
        alert("Object deleted successfully!");
      }
      getObjects();
      setSelectedObject(null);
    }
  }
  // #endregion
  // DELETE OBJECT

  // #region UPDATE OBJECT
  const [selectedObject, setSelectedObject] = useState(null);
  const [updatedObjectData, setUpdatedObjectData] = useState(null);

  useEffect(() => {
  if (selectedObject) {
    setUpdatedObjectData({ ...selectedObject });
  }
}, [selectedObject]);

function handleUpdateObjectChange(eventOrSelectedOption, propName) {
  // Check if the event is from the input field (has a target property)
  if (eventOrSelectedOption.target) {
    // It's an event from the input field, extract the value
    const newValue = eventOrSelectedOption.target.value;
    // Update the state here with newValue for propName
    // Assuming you have a way to update the selectedObject state, it might look something like this:
    setSelectedObject(prevState => ({
      ...prevState,
      [propName]: newValue,
    }));
  } else {
    // It's from the ReusableSelect, handle as before
    // Assuming eventOrSelectedOption is the selected option for ReusableSelect
    // Update the state here for propName with the selected option
    setSelectedObject(prevState => ({
      ...prevState,
      [propName]: eventOrSelectedOption, // Adjust based on how you need to store the selected option
    }));
  }
}


const updateObject = async () => {
  let objectId;

  // Check if updatedObjectData._id is a string that needs to be parsed
  if (typeof updatedObjectData._id === 'string') {
    try {
      const parsedId = JSON.parse(updatedObjectData._id);
      objectId = parsedId.$oid;
    } catch (error) {
      console.error('Error parsing _id:', error);
      // Handle the error (e.g., invalid JSON format)
    }
  } else if (typeof updatedObjectData._id === 'object' && updatedObjectData._id.$oid) {
    // If updatedObjectData._id is already an object with $oid property
    objectId = updatedObjectData._id.$oid;
  } else {
    console.error('Invalid _id format');
    // Handle invalid _id format
  }

  if (!objectId) {
    // Exit the function or handle the case where objectId is not set
    return;
  }

  delete updatedObjectData._id; 
  delete updatedObjectData.dateCreated; 

  const requestOptions = {
    method: "PUT",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify({
      id: objectId,
      data: updatedObjectData, // Ensure this contains all updated fields
    }),
  };


    console.log(requestOptions.body);
  
    try {
      const response = await fetch(
        "https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/default/objects",
        requestOptions
      );
      const data = await response.json();
      alert(JSON.stringify(data.body));
      console.log(data);
      getObjects(); // Refresh the objects list
      setSelectedObject(null); // Reset the selected object
    } catch (error) {
      alert("Error:", error);
    }
  };

  // #endregion
  // UPDATE OBJECT

  // #region UPDATE CLASS
  const [updateClassModal, setUpdateClassModal] = useState(false);
  const [updatedClassName, setUpdatedClassName] = useState("");

  useEffect(() => {
    if (selectedClass) {
      setUpdatedClassName(selectedClass.name);
    }
  }, [ selectedClass ]);

  const [updatedProperties, setUpdatedProperties] = useState([]);
  const inputRefs = useRef([]);

  useEffect(() => {
    // Simulating API call response
    let apiResponse = {};
    if (selectedClass) {
      const selectedClassObject =
          classes.find((classItem) => classItem.name === selectedClass.name);
      if (selectedClassObject) {
        apiResponse = selectedClassObject.jsonSchema.properties;
      }
    }

    // Transforming API response to desired format
    const transformedArray =
        Object.entries(apiResponse).map(([ name, value ]) => ({
                                          id : Math.random(),
                                          name,
                                          type : value.type,
                                          enum : typeof value.enum === "string"
                                                     ? value.enum.split(",")
                                                     : value.enum,
                                          description : value.description,
                                          required : "n",
                                        }));

    setUpdatedProperties(transformedArray);
  }, [ selectedClass ]);

  const addNewProp = (type) => {
    setUpdatedProperties(
        (prevArray) => [...prevArray,
                        {
                          id : Date.now(),
                          name : "",
                          type : type,
                          enum : type === "enum" ? "" : undefined,
                          description : "",
                          required : "",
                        },
    ]);
  };

  // Use the id to find the property that needs to be updated
  const handleChange = (id, key, value = null) => {
    setUpdatedProperties((prevArray) => {
      const newArray = [...prevArray ];
      const item = newArray.find((prop) => prop.id === id);
      const newValue =
          value !== null ? value : inputRefs.current[id][key].value;
      item[key] = newValue;
      return newArray;
    });
  };

  // Use the id to find the property that needs to be removed
  const removeProp = (id) => {
    setUpdatedProperties((prevArray) => {
      const newArray = [...prevArray ];
      const index = newArray.findIndex((prop) => prop.id === id);
      newArray.splice(index, 1);
      console.log(newArray);
      return newArray;
    });
  };

  const updateClass = async () => {
    const id = selectedClass._id.$oid;
    const abstractClass = selectedClass.AbstractClasses;
    const finalUpdatedProperties = updatedProperties.map(
        (prop) => ({
          ...prop,
          type : prop.type === "enum" ? "string" : prop.type,
          enum : typeof prop.enum === "string" ? prop.enum.split(",")
                                               : prop.enum,
        }));

    console.log(finalUpdatedProperties);

    const finalProperties = {
      id : id,
      name : updatedClassName,
      properties : finalUpdatedProperties,
      abstractClasses : abstractClass,
    };

    const requestOptions = {
      method : "PUT",
      headers : {"Content-Type" : "application/json"},
      body : JSON.stringify(finalProperties),
    };

    // FOR DEBUGGING
    console.log("new props are:", requestOptions.body);

    fetch(
        "https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/default/class",
        requestOptions)
        .then((response) => response.json())
        .then((data) => alert(JSON.stringify(data.body)))
        .then(() => getClasses())
        .then(() => getObjects())
        .then(() => setSelectedClass(null))
        .then(() => setUpdateClassModal(false))
        .catch((error) => console.error("Error:", error));
  };
  // #endregion
  // UPDATE CLASS

  // #region TABLE AND MODAL LOGIC
  const [selectedAbstractClass, setSelectedAbstractClass] = useState("");
  const [selectedClassForModal, setSelectedClassForModal] = useState(null);

  // #endregion
  // TABLE AND MODAL LOGIC

  // #region LEFT SIDEBAR LOGIC
  const [selectedAbstractClasses, setSelectedAbstractClasses] = useState([]);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  // #endregion
  // LEFT SIDEBAR LOGIC

  // #region SEARCHABLE SELECT LOGIC ON ADD CLASS PROP
  const unitOptions = [
    {value : "kw", label : "KW"}, {value : "mw", label : "MW"},
    {value : "kw/h", label : "KW/h"}, {value : "mw/h", label : "MW/h"},
    {value : "percentage", label : "percentage (%)"},
    {value : "kg", label : "KG"}, {value : "ton", label : "TON"},
    {value : "liter", label : "LITER"}, {value : "m3", label : "M3"},
    {value : "cm3", label : "CM3"}, {value : "euro", label : "€"},
    {value : "dollar", label : "$"}, {value : "pound", label : "£"},
    {value : "yen", label : "¥"}, {value : "lira", label : "₺"},

    // Add more options as needed
  ];

  // Add a new function to handle unit changes
  const handleUnitChange = (index, selectedOption) => {
    const values = [...properties ];
    values[index].unit = selectedOption.value;
    setProperties(values);
  };

  // #endregion
  // SEARCHABLE SELECT LOGIC

    // #region TABLE LOGIC

    const csvConfig = mkConfig({
      fieldSeparator : ",",
      decimalSeparator : ".",
      useKeysAsHeaders : true,
    });
  
    const FlexDBTable = () => {
      //should be memoized or stable
      const columns = useMemo(() => {
        if (!objects || objects.length === 0) return [];
  
        const filteredObjects = objects.filter(
          (object) => selectedClass && object.type === selectedClass.name
        );
        if (filteredObjects.length === 0) return [];
        const firstObject = { ...filteredObjects[0] };
        return Object.keys(firstObject).map((property) => ({
          accessorKey: property,
          header: property,
          minSize: 80, //min size enforced during resizing
          maxSize: 400, //max size enforced during resizing
          size: 120, //medium column
        }));
      }, [objects, selectedClass]);
  
      const data = useMemo(() => {
        if (!selectedClass) return [];
        return objects
          .filter((object) => object.type === selectedClass.name)
          .map((object) => {
            const data = {};
            for (const key in object) {
              if (Array.isArray(object[key])) {
                data[key] = object[key].map((item) => item.label).join(", ");
              } else if (
                typeof object[key] === "object" &&
                object[key] !== null
              ) {
                if ("$numberDouble" in object[key]) {
                  data[key] = isNaN(object[key].$numberDouble)
                    ? "NaN"
                    : object[key].$numberDouble;
                } else if ("label" in object[key]) {
                  data[key] = object[key].label;
                } else {
                  data[key] = JSON.stringify(object[key]);
                }
              } else {
                data[key] = object[key];
              }
            }
            return data;
          });
      }, [objects, selectedClass]);
  
      const handleExportRows = (rows) => {
        const rowData = rows.map((row) => row.original);
        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
      };
  
      const handleExportData = () => {
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
      };
  
      const table = useMaterialReactTable({
        columns,
        data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        enableColumnResizing: true,
        initialState: {
          density: "compact",
          columnVisibility: { _id: false, type: false, dateCreated: false },
          pagination: { pageIndex: 0, pageSize: 25 },
        },
        enableRowSelection: true,
        columnFilterDisplayMode: "popover",
        paginationDisplayMode: "pages",
        positionToolbarAlertBanner: "bottom",
        enableStickyHeader: true,
        muiTableContainerProps: { sx: { height: "69.5vh" } },
        enableRowActions: true,
        renderRowActionMenuItems: ({ row }) => [
        <Button key="edit" onClick={() => {
  
          const foundObject = objects.find((object) => object._id.$oid === JSON.parse(row.original._id).$oid);
  
          if (foundObject) {
            setSelectedObject(foundObject);
          } else {
            console.error('No matching object found');
            // Handle the case where no object is found
            // For example, you might want to clear the selection or show an error message
          }
        }}>
            Edit
          </Button>,
          <Button
            key="delete"
            onClick={() => {
              deleteObject(JSON.parse(row.original._id).$oid);
            }}
          >
            Delete
          </Button>,
        ],
        muiTableBodyRowProps: ({ row }) => ({
          onClick: (event) => {
            console.info(event, row.original);
          },
          sx: {
            cursor: "pointer", //you might want to change the cursor too when adding an onClick
          },
        }),
        renderTopToolbarCustomActions: ({ table }) => (
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              padding: "8px",
              flexWrap: "wrap",
            }}
          >
            <Button
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
            >
              Download All Data As CSV
            </Button>
            <Button
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              //only export selected rows
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
            >
              Download Selected Rows
            </Button>
          </Box>
        ),
      });
  
      return <MaterialReactTable table={table} />;
    };
  
    //#endregion
    // TABLE LOGIC

  // #region NETWORK GRAPH LOGIC
  const [tableView, setTableView] = useState(true);

  const [selectedComplexSystem, setSelectedComplexSystem] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [shownComplexSystems, setShownComplexSystems] = useState([]);

  useEffect(() => {
    setTableView(true);
  }, [selectedClass]);

  //#endregion
  // NETWORK GRAPH LOGIC

  // #region GET COMPLEX SYSTEMS

  const [complexSystems, setComplexSystems] = useState([]);

  const getComplexSystems = async () => {
    fetch("https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/default/tree")

      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setComplexSystems(data);
      })
      .catch((error) => console.log("Couldnt GET complex systems:", error));
  };

  useEffect(() => {
    getComplexSystems();
  }, []);

  //#endregion
  // GET COMPLEX SYSTEMS

  // #region ADD COMPLEX SYSTEMS

  let initalComplexSystemNodes = {
    id: null,
    label: "",
    level: 1,
    amount: 1,
  }

  let initalComplexSystemEdges = {
    from: null,
    to: null,
  }

  const [complexSystemName, setComplexSystemName] = useState("");
  const [complexSystemNodes, setComplexSystemNodes] = useState([initalComplexSystemNodes]);
  const [complexSystemEdges, setComplexSystemEdges] = useState([initalComplexSystemEdges]);
  const [addComplexSystemModal, setAddComplexSystemModal] = useState(false);
  const [selectedComplexSystemClass, setSelectedComplexSystemClass] = useState(null);

  let tempNodes =
    [{"id": 1, "label": "Node 1", "level": 1, "amount": 1},
        {"id": 2, "label": "Node 2", "level": 2, "amount": 1},
        {"id": 3, "label": "Node 3", "level": 3, "amount": 1},
        {"id": 4, "label": "Node 4", "level": 4, "amount": 1},
        {"id": 5, "label": "Node 5", "level": 4, "amount": 1},
        {"id": 6, "label": "Node 6", "level": 5, "amount": 1}]
    

    let tempEdges =
    [{"from": "662b577019a7337a2a8e18b4", "to": "663b5afaa106a1ddfb087f40" },
        {"from": "663b5afaa106a1ddfb087f40", "to": "662a45dc2737b358d7496ab9" },]

const addNode = () => {
  const lastNode = complexSystemNodes[complexSystemNodes.length - 1];
  const newLevel = lastNode ? lastNode.level + 1 : 1;
  const newNode = { ...initalComplexSystemNodes, level: newLevel };
  setComplexSystemNodes([...complexSystemNodes, newNode]);
};

const handleComplexSystemNodesChange = (index, newValues) => {
  setComplexSystemNodes(complexSystemNodes.map((node, i) => 
    i === index ? {...node, ...newValues} : node
  ));
};

  const deleteNode = (index) => {
    const updatedDataArray = complexSystemNodes.filter((item, idx) => idx !== index);
    setComplexSystemNodes(updatedDataArray);
  }

useEffect(() => {
  console.log(objects);
}, [objects]);

  useEffect(() => {
    console.log(complexSystemNodes);
  }, [complexSystemNodes]);


const addEdge = () => {
  const newEdge = { 
    ...initalComplexSystemEdges, 
    from: '', 
    to: '' 
  };
  setComplexSystemEdges([...complexSystemEdges, newEdge]);
};
const handleComplexSystemEdgesChange = (index, newValues) => {
  setComplexSystemEdges(complexSystemEdges.map((edge, i) => 
    i === index ? {...edge, ...newValues} : edge
  ));
};

useEffect(() => {
  console.log(complexSystemEdges);
}, [complexSystemEdges]);

const deleteEdge = (index) => {
  const updatedDataArray = complexSystemEdges.filter((item, idx) => idx !== index);
  setComplexSystemEdges(updatedDataArray);
};

const resetNodes = () => {
  setComplexSystemNodes([initalComplexSystemNodes]);
};

const resetEdges = () => {
  setComplexSystemEdges([initalComplexSystemEdges]);
};


  const addComplexSystems = async () => {

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          name: complexSystemName,
          nodes: complexSystemNodes,
          edges: complexSystemEdges,
        }),
    };
    console.log(requestOptions.body);

    fetch(
      "https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/default/tree",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => alert(JSON.stringify(data)))
      .then(() => getComplexSystems())
      .then(() => {
        setAddComplexSystemModal(false);
        setComplexSystemName("");
        setComplexSystemNodes([initalComplexSystemNodes]);
        setComplexSystemEdges([initalComplexSystemEdges]);})
      .catch((error) => console.error("Error:", error));
  }

  //#endregion
  // ADD COMPLEX SYSTEMS


  // #region DELETE COMPLEX SYSTEMS

  const deleteComplexSystem = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: id,
      }),
    };

    fetch(
      "https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/default/tree",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => alert(JSON.stringify(data)))
      .then(() => getComplexSystems())
      .then(() => setSelectedComplexSystem(null))
      .catch((error) => console.error("Error:", error));
  };

  //#endregion
  // DELETE COMPLEX SYSTEMS

  // #region GET PATH VIEWS

  const [pathViews, setPathViews] = useState([]);
  const [showPathViewModal, setShowPathViewModal] = useState(false);

  const getPathViews = async (tree_name, object_id) => {
    fetch("https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/dev/tree/")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setPathViews(data);
      })
      .catch((error) => console.log("Couldnt GET path views:", error));
  };

  useEffect(() => {
    getPathViews();
  }
  , []);

  // #endregion
  // GET PATH VIEWS

  // #region ADD PATH VIEWS

  const [addPathViewModal, setAddPathViewModal] = useState(false);
  const [pathViewName, setPathViewName] = useState("");
  const [pathViewRootClass, setPathViewRootClass] = useState("");
  const [pathView, setPathView] = useState("");

  let testPath = {
    "name": "_name",
    "children": [
        {
            "name": "TestEq",
            "children": [
                {
                    "name": "_name"
                }
            ]
        },
        {
            "name": "Currencies",
            "children": [
                {
                    "name": "_name"
                }
            ]
        }
    ]
}

  const addPathView = async () => {

    const textareaValue = document.getElementById('pathViewTextarea').value;

  let pathJSON = JSON.parse(textareaValue);
  let pathTest = {
    "name": pathViewName,
    "rootClass": pathViewRootClass,
    "pathDetails": pathJSON
  }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(pathTest),
    };

    console.log(requestOptions.body);

  fetch("https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/dev/tree/", requestOptions)
    .then((response) => response.json())
    .then((data) => alert(JSON.stringify(data.body)))
    .then(() => getPathViews())
    .then(() => {setAddPathViewModal(false); setPathViewName(""); setPathViewRootClass(""); setPathView("");})
    .catch((error) => console.error("Error:", error));
  }

  // #endregion
  // ADD PATH VIEWS



  // #region USER PERMISSIONS
  if (accounts && !accounts[0].idTokenClaims.roles.includes(window.location.pathname.split('/')[1]))
    {
      return (
        <div>
          <p>You don't have permission to see this app. </p>
        </div>
      )
    }
  // #endregion
  // USER PERMISSIONS

  return (
    <div className="flex-db-main">
      <div className={`flex-db-left-sidebar ${isSidebarCollapsed ? "collapsed" : ""} `}>
        <button
          id="flex-db-left-sidebar-collapse-button"
          title="Collapse Sidebar"
          onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
        >
          {isSidebarCollapsed ? ">" : "<"}
        </button>

        {!isSidebarCollapsed && (
          <>
            <div className="flex-db-left-sidebar-classes">
              <br />
              {abstractClasses.map((abstractClass, index) => (
                <div key={index}>
                  <button
                    onClick={() => {
                      if (
                        selectedAbstractClasses.includes(abstractClass.name)
                      ) {
                        setSelectedAbstractClasses(
                          selectedAbstractClasses.filter(
                            (name) => name !== abstractClass.name
                          )
                        );
                      } else {
                        setSelectedAbstractClasses([
                          ...selectedAbstractClasses,
                          abstractClass.name,
                        ]);
                      }
                    }}
                  >
                    {selectedAbstractClasses.includes(abstractClass.name) ? (
                      <FolderOpenIcon width="12px" height="12px" />
                    ) : (
                      <FolderIcon width="12px" height="12px" />
                    )}{" "}
                    {abstractClass.name}
                  </button>

                  <div className="flex-db-selected-classes-objects">
                    {selectedAbstractClasses.includes(abstractClass.name) &&
                      classes
                        .filter((classItem) =>
                          classItem.AbstractClasses.includes(abstractClass.name)
                        )
                        .map((classItem, classIndex) => (
                          <button
                              key={classIndex}
                              onClick={() => {
                                setSelectedClass(classItem);
                                setTableView(true);
                              }}
                              style={
                                selectedClass === classItem
                                  ? { backgroundColor: "#f0f0f0" }
                                  : { backgroundColor: "#fff" }
                              }
                            >
                            <DotIcon /> {classItem.name}{" "}
                            {isAdmin && (
                              <span
                                title="Edit Sub Category"
                                className="flex-db-edit-class-button"
                                onClick={() => setUpdateClassModal(true)}
                              >
                                ...
                              </span>
                            )}
                          </button>
                        ))}
                    {isAdmin &&
                      selectedAbstractClasses.includes(abstractClass.name) && (
                        <button
                          onClick={() => {
                            setaddClassModal(true);
                            setSelectedClassForModal(abstractClass.name);
                          }}
                        >
                          <DotIcon /> + Add {abstractClass.name} Sub Category
                        </button>
                      )}
                  </div>
                </div>
              ))}
              {isAdmin && (
                <button onClick={() => setIsAbstractModalOpen(true)}>
                  <FolderIcon width="12px" height="12px" /> + Add Category
                </button>
              )}
              <br />
            </div>
            <div className="flex-db-left-sidebar-subcategories">
              <br />
              {classes
                .filter(
                  (classItem) =>
                    !classItem.AbstractClasses ||
                    classItem.AbstractClasses.every(
                      (val) => val === null || val === ""
                    )
                )
                .map((classItem, classIndex) => (
                  <button
                    key={classIndex}
                    onClick={() => setSelectedClass(classItem)}
                    style={
                      selectedClass === classItem
                        ? { backgroundColor: "#f0f0f0" }
                        : { backgroundColor: "#fff" }
                    }
                  >
                    <DotIcon /> {classItem.name}{" "}
                    {isAdmin && (
                      <span
                        title="Edit Sub Category"
                        className="flex-db-edit-class-button"
                        onClick={() => setUpdateClassModal(true)}
                      >
                        ...
                      </span>
                    )}
                  </button>
                ))}
              {isAdmin && (
                <button onClick={() => setaddClassModal(true)}>
                  <DotIcon /> + Add Sub Category
                </button>
              )}
            </div>
            <div className={`flex-db-left-sidebar-visualization ${isExpanded ? "expanded" : ""}`}>
              <button onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? 
                <>
                  <FolderOpenIcon width="12px" height="12px" />
                  <span> Hide Views</span>
                </> 
                : 
                <>
                  <FolderIcon width="12px" height="12px" />
                  <span> Show Views</span>
                </>
              }

              </button>
              <br />
              {isExpanded && (
                <div>
                  {complexSystems.map((system, index) => (
                    <div key={index}>
                    <button onClick={() => {
                      setSelectedComplexSystem(system);
                      setNodes(system.nodes);
                      setEdges(system.edges);
                      setTableView(false);
                    }}>{system.name}</button>
                      {isAdmin && (
                        <button
                          id="flex-db-delete-class-button"
                          onClick={() => deleteComplexSystem(system._id.$oid)}
                          title={`Delete ${system.name} Complex System`}
                        >
                          <TrashIcon width="1.5vw" height="1.5vh" />
                        </button>
                      )}
                    </div>
                  )
                  )
                  }
                  <button onClick={() => setAddComplexSystemModal(true)}><DotIcon /> + Add New Complex System</button>
                  <button onClick={() => setAddPathViewModal(true)}><DotIcon /> + Add New Path View</button>
                  <button onClick={() => setShowPathViewModal(true)}><DotIcon /> Show Path Views</button>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div className={`flex-db-right-sidebar ${isSidebarCollapsed ? "expanded" : ""}`}
      >
        <div className="flex-db-table-div">
          {(selectedClass || selectedComplexSystem) && (
            <>


              {/* MAIN TABLE OR NETWORK DIV */}
              {!tableView ? (
                <div className="flex-db-table-div-network">
                  <div className="flex-db-table-div-network-graph">
                  <p>{selectedComplexSystem && selectedComplexSystem.name}</p>
                  {nodes && edges && (
                    <NetworkGraph nodes={nodes} edges={edges} />
                  )}

                  </div>
                    <div className="flex-db-table-div-network-right-sidebar">
                      <ul>
                        {selectedComplexSystem ? (
                          Object.entries(selectedComplexSystem).map(([key, value]) => (
                            <li key={key}>
                              {`${JSON.stringify(key)}: ${
                                typeof value === 'object' ? JSON.stringify(value, null, 2).replace(/\n/g, '\n') : JSON.stringify(value)
                              }`}
                            </li>
                          ))
                        ) : (
                          <li>No system selected</li>
                        )}
                      </ul>
                    </div>
                  


              </div>
              ) : (

                <>
                <div className="flex-db-table-div-buttons">
                <p>{selectedClass && selectedClass.name}</p>


                {isPro && selectedClass && (
                  <button
                    onClick={() => {
                      setAddObjectModalOpen(true);
                      setObjectName(""); // Reset objectName
                      setInputValues({}); // Reset inputValues
                    }}
                  >
                    + Add New {selectedClass.name}
                  </button>
                )}

                {isAdmin && selectedClass && (
                  <button
                    id="flex-db-delete-class-button"
                    onClick={() => setSelectedDeleteClass(selectedClass)}
                    title={`Delete ${selectedClass.name} Sub Category`}
                  >
                    <TrashIcon width="1.5vw" height="1.5vh" />
                  </button>
                )}{" "}
                </div>
                <div className="flex-db-table-div-table">
                <FlexDBTable />
               </div>
                </>
              )
              }



            </>
          )}
        </div>


      </div>

      {/* MODALS */}

      {/* Add Category Modal */}
      <ReusableModal
        isOpen={isAbstractModalOpen}
        onClose={() => setIsAbstractModalOpen(false)}
      >
        <div className="flex-db-add-new-abstract-class">
          <h2>Add New Category</h2>
          <label>
            Category Name <br />
            <input
              type="text"
              value={abstractClassName}
              onChange={(e) => setAbstractClassName(e.target.value)}
            />
          </label>
          <button onClick={addAbstractClass}>Add Category</button>
        </div>
      </ReusableModal>

      {/* Add Sub Category Modal */}
      <ReusableModal
        isOpen={addClassModal}
        onClose={() => setaddClassModal(false)}
      >
        <div className="flex-db-add-class-modal">
          <h2>
            Add New {selectedClassForModal && selectedClassForModal} Sub
            Category
          </h2>
          <h3>Sub Category Name</h3>
          <input
            id="flex-db-add-class-name-input"
            placeholder="Enter Sub Category Name"
            type="text"
            value={className}
            onChange={(e) => setClassName(e.target.value)}
          />

          <div className="flex-db-add-new-class-modal-properties">
            <h3>Properties  </h3>
            <p>Warning: Don't add name property it will be added automatically</p>
            {properties.map((property, index) => (
              <div
                key={index}
                className="flex-db-add-new-class-modal-added-properties"
              >
                <ReusableSelect
                  options={[
                    { value: "string", label: "String" },
                    { value: "number", label: "Number" },
                    { value: "", label: "List" },
                    { value: "enum", label: "Enum" },
                    { value: "array", label: "Linked Object" },
                  ]}
                  onChange={(selectedOption) => {
                    handlePropertyChange(index, "type", selectedOption.value);
                    if (selectedOption.value === "array") {
                      handlePropertyChange(index, "name", selectedOption.label);
                    }
                  }}
                  defaultValue={
                    property.type
                      ? { value: property.type, label: property.type }
                      : null
                  }
                  width={"7vw"}
                  placeholder="Type"
                />
                {property.type !== "array" && (
                  <input
                    type="text"
                    name="name"
                    value={property.name}
                    onChange={(event) =>
                      handlePropertyChange(index, "name", event.target.value)
                    }
                    placeholder="Property Name"
                  />
                )}

                {property.type === "enum" && (
                  <input
                    type="text"
                    name="enumValues"
                    value={property.enumValues}
                    onChange={(event) =>
                      handlePropertyChange(
                        index,
                        "enumValues",
                        event.target.value
                      )
                    }
                    placeholder="Enum key values (comma-separated)"
                  />
                )}
                {property.type === "array" && (
                  <>
                    <ReusableSelect
                      options={classes.map((classItem) => ({
                        value: classItem.name,
                        label: classItem.name,
                      }))}
                      onChange={(selectedOption) => {
                        setSelectedClassForLinkedObjects((prevState) => ({
                          ...prevState,
                          [index]: selectedOption.value,
                        }));
                      }}
                      defaultValue={
                        property.object
                          ? { value: property.object, label: property.object }
                          : null
                      }
                      width={"12vw"}
                      placeholder="Object Class"
                    />
                  </>
                )}
                <input
                  type="text"
                  name="description"
                  value={property.description}
                  onChange={(event) =>
                    handlePropertyChange(
                      index,
                      "description",
                      event.target.value
                    )
                  }
                  placeholder="Description"
                />
                {(property.type == "string" || property.type == "number") && (
                  <ReusableSelect
                    options={unitOptions}
                    isSearchable
                    placeholder="Unit"
                    onChange={(selectedOption) =>
                      handlePropertyChange(index, "unit", selectedOption.value)
                    }
                    defaultValue={
                      property.unit
                        ? { value: property.unit, label: property.unit }
                        : null
                    }
                    width={"7vw"}
                  />
                )}
                <ReusableSelect
                  options={[
                    { value: "y", label: "Yes" },
                    { value: "n", label: "No" },
                  ]}
                  onChange={(selectedOption) =>
                    handlePropertyChange(
                      index,
                      "required",
                      selectedOption.value
                    )
                  }
                  defaultValue={
                    property.required
                      ? { value: property.required, label: property.required }
                      : null
                  }
                  width={"7vw"}
                  placeholder="Required?"
                />
                <a onClick={() => handleDeleteProperty(index)}> X</a>
              </div>
            ))}
            <button id="addPropertyButton" onClick={handleAddProperty}>
              +
            </button>
          </div>
          <button id="addClassButton" onClick={addClass}>
            Add Sub Category
          </button>
        </div>
      </ReusableModal>

      {/* Update Sub Category Modal */}
      <ReusableModal
        isOpen={updateClassModal}
        onClose={() => setUpdateClassModal(false)}
      >
        {selectedClass && (
          <div className="flex-db-update-class-modal">
            <h2>Update {selectedClass.name} Sub Category</h2>
            <label>
              Sub Category Name {' '}
              <input
                type="text"
                value={updatedClassName}
                onChange={(e) => setUpdatedClassName(e.target.value)}
              />
            </label>
            <br />
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Enum Values</th>
                  <th>Description</th>
                  <th>Required</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {updatedProperties.map((prop) => (
                <tr key={prop.id}>
                    {/* Name Column */}
                    <td>
                      {prop.type == "array" ? (
                        <ReusableSelect
                          options={classes.map((classItem) => ({
                            value: classItem.name,
                            label: classItem.name,
                          }))}
                          onChange={(selectedOption) => {
                            handleChange(prop.id, "name", selectedOption.value);
                          }}
                          ref={(el) =>
                            (inputRefs.current[prop.id] = {
                              ...inputRefs.current[prop.id],
                              name: el,
                            })
                          }
                          defaultValue={prop.name ? { value: prop.name, label: prop.name } : null}
                          placeholder="Class Name"
                        />
                      ) : (
                        <input
                          type="text"
                          ref={(el) =>
                            (inputRefs.current[prop.id] = {
                              ...inputRefs.current[prop.id],
                              name: el,
                            })
                          }
                          defaultValue={prop.name}
                          onChange={() => handleChange(prop.id, "name")}
                        />
                      )}
                    </td>
                    {/* Type Column */}
                    <td>
                      <select
                        ref={(el) =>
                          (inputRefs.current[prop.id] = {
                            ...inputRefs.current[prop.id],
                            type: el,
                          })
                        }
                        defaultValue={prop.type}
                        onChange={() => handleChange(prop.id, "type")}
                      >
                        <option selected>Choose A Type</option>
                        <option value="string">String</option>
                        <option value="number">Number</option>
                        <option value="array">Linked Object</option>
                        <option value="enum">Enum</option>
                      </select>
                    </td>
                    {/* Enum Values Column */}
                    <td>
                      {prop.type === "enum" || prop.enum ? (
                        <input
                          type="text"
                          ref={(el) =>
                            (inputRefs.current[prop.id] = {
                              ...inputRefs.current[prop.id],
                              enum: el,
                            })
                          }
                          defaultValue={prop.enum}
                          onChange={() => handleChange(prop.id, "enum")}
                        />
                      ) : (
                        "-"
                      )}{" "}
                    </td>
                    {/* Description Column */}
                    <td>
                      <input
                        type="text"
                        ref={(el) =>
                          (inputRefs.current[prop.id] = {
                            ...inputRefs.current[prop.id],
                            description: el,
                          })
                        }
                        defaultValue={prop.description}
                        onChange={() => handleChange(prop.id, "description")}
                      />
                    </td>
                    {/* Required Column */}
                    <td>
                      <select
                        ref={(el) =>
                          (inputRefs.current[prop.id] = {
                            ...inputRefs.current[prop.id],
                            required: el,
                          })
                        }
                        defaultValue={prop.required}
                        onChange={() => handleChange(prop.id, "required")}
                      >
                        <option value="n">No</option>
                        <option value="y">Yes</option>
                      </select>
                    </td>
                    {/* Delete Column */}
                    <td>
                      <button onClick={() => removeProp(prop.id)}>X</button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="2">
                    <button onClick={addNewProp}>Add New Prop</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button onClick={updateClass}>Update Properties</button>
          </div>
        )}
      </ReusableModal>

      {/* Add Object Modal */}
      <ReusableModal
        isOpen={addObjectModalOpen}
        onClose={() => setAddObjectModalOpen(false)}
      >
        <div className="flex-db-add-new-object-modal">
          <br />
          <div className="flex-db-add-new-object-modal-bulk">
            <label>
              <h2>Add Bulk {selectedClass && selectedClass.name}</h2>
            </label>
            <input type="file" id="bulkCsvFile" onChange={handleCsvInput} />
            <button onClick={addBulkObjects}>Add Bulk Objects</button>
          </div>
          <br />
          <div className="flex-db-add-new-object-modal-objectname">
            <h2>Add New {selectedClass && selectedClass.name}</h2>
            <br />
            <br />

            {selectedClass &&
               [
                "_name",
                ...Object.keys(selectedClass.jsonSchema.properties).filter(
                  (prop) => prop !== "_name"
                ),
              ].map((prop, index) => {
                const propDetails = selectedClass.jsonSchema.properties[prop];
                const propType = propDetails.type;
                const displayName = prop === "_name" ? "Name" : prop;
                  if (propDetails.enum) {
                    // Handle enum types as select dropdown with input afterwards
                    return (
                      <div
                        key={index}
                        className="flex-db-add-new-object-modal-inputs-enum"
                      >
                        <label>{`${prop}:`}</label>
                        <select
                          onChange={(e) => handleSelectChange(e, prop)}
                          required={selectedClass.jsonSchema.required.includes(
                            prop
                          )}
                        >
                          <option value="" disabled selected>
                            Select Value
                          </option>
                          {propDetails.enum.map((enumValue, enumIndex) => (
                            <option key={`enum-${enumIndex}`} value={enumValue}>
                              {enumValue}
                            </option>
                          ))}
                        </select>
                      </div>
                    );
                  } else if (propType === "array" || propType === "object") {
                    // Handle object types as select dropdown with multiple select
                    return (
                      <div
                        key={index}
                        className="flex-db-add-new-object-modal-inputs-object"
                      >
                        <label>{`${prop}:`}</label>
                        <ReusableSelect
                          options={objects
                            .filter((objectItem) => objectItem.type === prop)
                            .map((objectItem) => {
                            return {
                              value: objectItem,
                              label: `${objectItem._name}`,
                            };
                          })}
                          onChange={(selectedOption) => {
                            handleSelectChange(selectedOption, prop);
                          }}
                          isMulti
                          width={"20vw"}
                          required={selectedClass.jsonSchema.required.includes(
                            prop
                          )}
                        />
                        </div>
                    );
                  } else {
                    // Handle number and text types
                    return (
                      <div
                        key={index}
                        className="flex-db-add-new-object-modal-inputs"
                      >
                        <label>{displayName}:</label>
                        <input
                          type={propType === "number" ? "number" : "text"}
                          onChange={(e) => handleInputChange(e, prop)}
                          required={selectedClass.jsonSchema.required.includes(
                            prop
                          )}
                          placeholder={
                            selectedClass.jsonSchema.required.includes(prop)
                              ? "Required " + propType
                              : "" + propType
                          }
                        />
                      </div>
                    );
                  }
                }
              )}

            <button onClick={addObject}>Add Object</button>
          </div>
        </div>
      </ReusableModal>

      {/* Update Object modal */}
      <ReusableModal
        isOpen={selectedObject !== null}
        onClose={() => {setSelectedObject(null); setUpdatedObjectData(null)} }
      >
              {selectedObject && (
                <div className="flex-db-object-details-modal">
                  <div className="flex-db-object-details-modal-left">
                    <h2>Update {selectedObject._name}</h2>
                      {classes && Object.entries(classes.find(cls => cls.name === selectedObject.type).jsonSchema.properties).map(([propName, propDetails]) => {
                        if (propDetails.type === 'array') {
                          // Prepare options for ReusableSelect
                          const selectOptions = objects
                            .filter((objectItem) => objectItem.type === propName)
                            .map((objectItem) => ({
                              value: objectItem._id.$oid, // Assuming _id is the unique identifier
                              label: objectItem._name,
                            }));
                          

                            // Adjusted logic for handling both array and non-array values for selectedObject[propName]
                              const adjustedDefaultValue = selectOptions.filter(option => {
                                if (Array.isArray(selectedObject[propName])) {
                                  return selectedObject[propName].some(selectedItem => selectedItem.label === option.label);
                                } else {
                                  // Assuming that if it's not an array, it could be directly comparable or null/undefined
                                  return selectedObject[propName] ? selectedObject[propName].label === option.label : false;
                                }
                              });

                          return (
                            <div key={propName}>
                              <label>{propName}</label>
                              <ReusableSelect
                                options={selectOptions}
                                defaultValue={adjustedDefaultValue}
                                onChange={(selectedOption) => {
                                  // Assuming handleSelectChange properly updates the selectedObject state
                                  handleUpdateObjectChange(selectedOption, propName);
                                }}
                                isMulti={true}
                                width={"31vw"}
                                placeholder={`Select ${propName}`}
                              />
                            </div>
                          );
                        }
                        else if (propDetails.enum) {
                          // Logic for enum values
                          const enumOptions = propDetails.enum.map(enumValue => ({
                            value: enumValue,
                            label: enumValue, // Assuming you want the display text to be the same as the enum value
                          }));
                      
                          return (
                            <div key={propName}>
                              <label>{propName}</label>
                              <ReusableSelect
                                options={enumOptions}
                                defaultValue={enumOptions.find(option => option.value === selectedObject[propName])}
                                onChange={(selectedOption) => {
                                  // Assuming handleUpdateObjectChange properly updates the selectedObject state
                                  handleUpdateObjectChange(selectedOption.value, propName);
                                }}
                                width={"31vw"}
                                placeholder={`Select ${propName}`}
                              />
                            </div>
                          );
                        }
                        
                        else {
                          // Render input for other types
                          return (
                            <div key={propName}>
                              <label htmlFor={propName}>{ propName}</label>
                              <input
                                type="text"
                                id={propName}
                                value={selectedObject[propName] || ''}
                                onChange={(e) => handleUpdateObjectChange(e, propName)}
                              />
                            </div>
                          );
                        }
                      })}
                    <button onClick={updateObject}>Update Object</button>
                  </div>
                </div>
              )}
      </ReusableModal>

      {/* Add Complex System Modal */}
      <ReusableModal
        isOpen={addComplexSystemModal}
        onClose={() => setAddComplexSystemModal(false)}
      >
        <div className="flex-db-add-complex-system-modal">
        <h2>Add New Complex System</h2>
          <label>
            Complex System Name <br />
            <input
              type="text"
              value={complexSystemName}
              onChange={(e) => setComplexSystemName(e.target.value)}
            />
            <br />
            <br />
          </label>
          <div className="flex-db-add-complex-system-modal-nodes-and-edges">
          <div className="flex-db-add-complex-system-modal-node">
          
            <h3>Add Objects</h3>
            <table>
              <thead>
                <tr>
                  <th>Class</th>
                  <th>Object</th>
                  <th>Level</th>
                  <th>Amount</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {complexSystemNodes.map((node, index) => (
                  <tr key={index}>
                    <td>
                      <ReusableSelect
                        options={classes.map((classItem) => ({
                          value: classItem.name,
                          label: classItem.name,
                        }))}
                        onChange={(selectedOption) => {
                          setSelectedComplexSystemClass(selectedOption.value);
                        }}
                        width={"12vw"}
                        placeholder="Class Name"
                      />
                    </td>
                    <td>
                      <ReusableSelect
                        options={objects
                          .filter((objectItem) => objectItem.type === selectedComplexSystemClass)
                          .map((objectItem) => {
                            const firstProperty = Object.keys(objectItem)[1];
                            const secondProperty = Object.keys(objectItem)[2];
                            const label = objectItem._name 
                              ? objectItem._name 
                              : `${objectItem[firstProperty]} ${objectItem[secondProperty]}`;
                            return {
                              value: objectItem,
                              label: label,
                            };
                          })}
                          onChange={(e) => {
                            console.log(e);
                            handleComplexSystemNodesChange(index, {label: e.label, id: e.value._id.$oid});
                          }}
                        width={"12vw"}
                        placeholder="Object"
                      />
                    </td>
                    <td>
                    <input
                      type="number"
                      value={node.level}
                      onChange={(e) => {
                        handleComplexSystemNodesChange(index, {level: Number(e.target.value)});
                      }}
                    />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={node.amount}
                        onChange={(e) => {
                          handleComplexSystemNodesChange(index, {amount: Number(e.target.value)});
                        }}
                    />
                    </td>
                    <td>
                    <button className="flex-db-add-complex-system-modal-table-delete-button" onClick={() => deleteNode(index)}>X</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
              <button className="flex-db-add-complex-system-modal-add-reset-button" onClick={addNode}>Add Node</button>
              <button className="flex-db-add-complex-system-modal-add-reset-button" onClick={resetNodes}>Reset</button>
              
          </div>
          <div className="flex-db-add-complex-system-modal-edge">
            <h3>Add Links</h3>
            <table>
              <thead>
                <tr>
                  <th>From</th>
                  <th>To</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {complexSystemEdges.map((edge, index) => (
                  <tr key={index}>
                    <td>
                      <select
                        value={edge.from}
                        onChange={(e) => handleComplexSystemEdgesChange(index, {from: e.target.value})}
                      >
                        <option value="">Please select an option</option>
                        {complexSystemNodes.map((node) => (
                          <option key={node.id} value={node.id}>{node.label}</option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        value={edge.to}
                        onChange={(e) => handleComplexSystemEdgesChange(index, {to: e.target.value})}
                      >
                        <option value="">Please select an option</option>
                        {complexSystemNodes.map((node) => (
                          <option key={node.id} value={node.id}>{node.label}</option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <button className="flex-db-add-complex-system-modal-table-delete-button" onClick={() => deleteEdge(index)}>X</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
              <button className="flex-db-add-complex-system-modal-add-reset-button" onClick={addEdge}>Add Edge</button>
              <button className="flex-db-add-complex-system-modal-add-reset-button" onClick={resetEdges}>Reset</button>
              </div>
              
              
        </div>
        <br />
        <button id="addComplexSystemButton" onClick={() => {addComplexSystems();}}>Add Complex System</button>
        </div>
      </ReusableModal>

      {/* Add Path View Modal */}
      <ReusableModal
        isOpen={addPathViewModal}
        onClose={() => setAddPathViewModal(false)}
      >
        <div className="flex-db-add-path-view-modal">
          <h2>Add Path View</h2>
          <label>
            Path View Name <br />
            <input
              type="text"
              placeholder="Enter Path View Name"
              value={pathViewName}
              onChange={(e) => setPathViewName(e.target.value)}
            />
            <br />
            <input 
              type="text"
              placeholder="Enter Root Class"
              value={pathViewRootClass}
              onChange={(e) => setPathViewRootClass(e.target.value)}
            />
            <br />
            <textarea
              rows="25"
              cols="50"
              id="pathViewTextarea"
              placeholder="Enter Path View"
              value={pathView}
              onChange={(e) => setPathView(e.target.value)}
            >

            </textarea>
            <br />
          </label>
          <button onClick={addPathView}>Add Path View</button>
            <br />
            <br />
            <p>
              Example Path View: {JSON.stringify(testPath)}
            </p>
        </div>
      </ReusableModal>

      {/* Show Path Views Modal */}
      <ReusableModal
        isOpen={showPathViewModal}
        onClose={() => setShowPathViewModal(false)}
      >
        <div className="flex-db-path-views-modal">
          <h2>Path Views</h2>
          <ul>
            {pathViews.map((pathView, index) => (
              <li key={index}>
                <h3>{pathView.name}</h3>
                <p>{pathView.rootClass}</p>
                <p>{JSON.stringify(pathView.pathDetails)}</p>
              </li>
            ))}
          </ul>
        </div>
      </ReusableModal>
    </div>
  );
}

export default FlexDB;
