import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Routes, Route, Navigate } from 'react-router-dom';

const AuthRoute = ({ children }) => {
  const { accounts } = useMsal();

  if (accounts.length > 0) {
    return <Routes>{children}</Routes>;
  } else {
    // Redirect unauthenticated users to the main page
    return <Navigate to="/" />;
  }
};

export default AuthRoute;
