import React, { useEffect, useState, useRef } from "react";
import success_image_1 from "./gifs/mv-tool-success.gif";
import loading_image_1 from "./gifs/mv-tool-loading.gif";
import "./styles/mvtool.css";
import { useMsal } from "@azure/msal-react";
import AWS from "aws-sdk";
import { SecretsManagerClient, GetSecretValueCommand} from "@aws-sdk/client-secrets-manager";

function Mvtool() {
//#region STATE VARIABLES FOR THE MAIN TABS AND SUB-TABS
  const [activeMainTab, setActiveMainTab] = useState("home");
  const [activeSubTab, setActiveSubTab] = useState("customerTable");
  const mappingInputsRef = useRef(null); // Ref for the inputs in the column mappings tab (Needed to be on top level to be used in the updateColumnMappings function)
  
  const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", process.env.REACT_APP_MV_TOOL_API_KEY);
    const awsAccessKeyId = process.env.REACT_APP_MV_TOOL_AWS_ACCESS_KEY_ID;
    const awsSecretAccessKey = process.env.REACT_APP_MV_TOOL_AWS_SECRET_ACCESS_KEY;
  //#endregion
//STATE VARIABLES FOR THE MAIN TABS AND SUB-TABS

//#region AWS SECRETS MANAGER
const secret_name = "mv-tool-database-user";

const client = new SecretsManagerClient({
  region: "eu-west-1",
  credentials: {
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
  },
});

let response;

async function getSecret() {
  let response;
  try {
    response = await client.send(
      new GetSecretValueCommand({
        SecretId: secret_name,
        VersionStage: "AWSCURRENT", // VersionStage defaults to AWSCURRENT if unspecified
      })
    );
  } catch (error) {
    // For a list of exceptions thrown, see
    // https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
    throw error;
  }
  return response;
}

// const secret = response.SecretString;

// #endregion
//AWS SECRETS MANAGER

//#region PRELOAD GIFS
  const [img1, setImg1] = useState(null);
  const [img2, setImg2] = useState(null);

  useEffect(() => {
    const image1 = new Image();
    const image2 = new Image();
    image1.src = success_image_1;
    image2.src = loading_image_1;

    image1.onload = () => setImg1(success_image_1);
    image2.onload = () => setImg2(loading_image_1);
  }, []);
  //#endregion
//PRELOAD GIFS

//#region FETCH CUSTOMERS FROM POSTGRE WITH LAMBDA



  const [customers, setCustomers] = useState(null);

  const fetchCustomers = () => {
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  fetch("https://gqyzj5g642.execute-api.eu-west-1.amazonaws.com/prod/sites", requestOptions)
    .then((response) => response.json())
    .then((result) => setCustomers(result))
    .catch((error) => console.error(error));

};




  useEffect(() => {
    if (!customers) {
      fetchCustomers();
    }
  }, [activeSubTab, activeMainTab, customers]);

  //#endregion
//FETCH CUSTOMERS FROM POSTGRE WITH LAMBDA

//#region ADD CUSTOMER

  const [selectedCustomerTemplateFile, setSelectedCustomerTemplateFile] =
    useState(null);
  const [customerTemplateFileName, setCustomerTemplateFileName] = useState(
    "Select Customer Template File"
  );

  const handleCustomerTemplateFileChange = (event) => {
    setSelectedCustomerTemplateFile(event.target.files[0]);
    setCustomerTemplateFileName(
      event.target.files[0]
        ? event.target.files[0].name
        : "Select Customer Template File"
    );
  };

  const uploadCustomerTemplateFile = async () => {
    // S3 Bucket Name
    const S3_BUCKET = "mv-tool-customer-templates";

    // S3 Region
    const REGION = "eu-west-1";

    // S3 Credentials
    AWS.config.update({
      accessKeyId: awsAccessKeyId,
      secretAccessKey: awsSecretAccessKey,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    // Files Parameters

    const params = {
      Bucket: S3_BUCKET,
      Key: `templates/${customerTemplateFileName}`,
      Body: selectedCustomerTemplateFile,
    };

    // Uploading file to s3

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    await upload.then((err, data) => {
      console.log(err);
      // File successfully uploaded
      console.log("File uploaded successfully.");
    });
  };

  function addCustomer() {
    const customerData = {
      id: Number(document.getElementById("id").value),
      name: document.getElementById("name").value,
      entity_id: document.getElementById("entity_id").value,
      customer_name: document.getElementById("customer_name").value,
      certificate_power: Number(document.getElementById("certificate_power").value),
      power_unit: document.getElementById("power_unit").value,
      level_change_value: Number(document.getElementById("level_change_value").value),
      level_change_unit: document.getElementById("level_change_unit").value,
      k_value: Number(document.getElementById("k_value").value),
      k_value_unit: document.getElementById("k_value_unit").value,
      frequency_value: Number(document.getElementById("frequency_value").value),
      frequency_unit: document.getElementById("frequency_unit").value,
      soc_max_mwh: Number(document.getElementById("soc_max_mw").value),
      soc_min_mwh: Number(document.getElementById("soc_min_mw").value),
      useful_energy_mwh: Number(document.getElementById("useful_energy_mwh").value),
      template:"templates/" +document.getElementById("template").value.replace(/C:\\fakepath\\/g, ""),};

    


    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(customerData),
      redirect: "follow",
    };

    fetch(
      "https://gqyzj5g642.execute-api.eu-west-1.amazonaws.com/prod/sites",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => alert(JSON.parse(result).message))
      .then(uploadCustomerTemplateFile)
      .then(fetchCustomers)
      .catch((error) => console.error(error));

    // Upload the template docx file to S3
  }

  //#endregion
//ADD CUSTOMER

//#region DELETE CUSTOMER

  function deleteCustomer(customerId) {
    
    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://gqyzj5g642.execute-api.eu-west-1.amazonaws.com/prod/sites?id=${customerId}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => alert(JSON.parse(result).message))
      .then(fetchCustomers)
      .catch((error) => console.error(error));
  }
  //#endregion
//DELETE CUSTOMER

//#region UPDATE CUSTOMER
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);

  const fieldNames = [
    "id",
    "name",
    "entity_id",
    "customer_name",
    "certificate_power",
    "power_unit",
    "level_change_value",
    "level_change_unit",
    "k_value",
    "k_value_unit",
    "frequency_value",
    "frequency_unit",
    "created_at",
    "soc_max_mw",
    "soc_min_mw",
    "useful_energy_mwh",
    "template",
  ];

  // Function to open the modal and set the current customer
  const openModal = (customer) => {
    setCurrentCustomer(customer);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to handle the update
  // Initialize state for the updated customer values
  // Initialize customerUpdateValues as an empty object
  const [customerUpdateValues, setCustomerUpdateValues] = useState({});

  // Function to handle input changes
  const handleUpdateInputChange = (event) => {
    setCustomerUpdateValues({
      ...customerUpdateValues,
      [event.target.name]: event.target.value,
    });
  };

  // Function to handle the file input change
  useEffect(() => {
    if (selectedCustomerTemplateFile) {
      setCustomerUpdateValues({
        ...customerUpdateValues,
        template: `templates/${customerTemplateFileName}`,
      });
    }
  }, [selectedCustomerTemplateFile]);

  // Function to update the customer
  const updateCustomer = () => {
    if (selectedCustomerTemplateFile) {
      uploadCustomerTemplateFile();
    }

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(customerUpdateValues),
      redirect: "follow",
    };

    fetch(
      `https://gqyzj5g642.execute-api.eu-west-1.amazonaws.com/prod/sites?id=${currentCustomer[0]}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => alert(JSON.parse(result).message))
      .then(fetchCustomers)
      .then(closeModal)
      .catch((error) => console.error(error));
  };

  //#endregion
//UPDATE CUSTOMER

//#region CREATE DOCX NAME TO BE USED IN THE GENERATE REPORT FUNCTION
  const [customerName, setCustomerName] = useState("");
  const [docxName, setDocxName] = useState("");

  const { accounts } = useMsal();
  const userName = accounts[0].idTokenClaims.name;

  useEffect(() => {
    console.log(userName);
  }, [userName]);

  const createDocxName = (newCustomerName) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();

    setDocxName(
      `${newCustomerName.replace(/\s/g, "")}_${userName.replace(
        /\s/g,
        ""
      )}_${year}_${month}_${day}T${hour}_${minutes}`
    );
  };

  useEffect(() => {
    createDocxName(customerName);
    console.log(docxName);
  }, [customerName]);
  //#endregion
//CREATE DOCX NAME

//#region UPLOAD CSV FILE THEN TRIGGER S3 TO CREATE THE DOCX THEN POWER AUTOMATE TO GENERATE PDF (THE GENERATE BUTTON & DOWNLOAD PDF BUTTON)
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("Select Customer Data File");
  const [oneDriveLink, setOneDriveLink] = useState("");
  const [isDone, setisDone] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [siteId, setsiteId] = useState(1);
  const url = "https://prod-05.westeurope.logic.azure.com:443/workflows/8542c51d33304274b7ad1ae5dfc6b2a2/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=kGVEpG78eomtQ0t22Im2chTDjwkDlpZRt2V0mdzpeXk";

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileName(
      event.target.files[0]
        ? event.target.files[0].name
        : "Select Customer Data File"
    );
    console.log(fileName);
    console.log(docxName);
  };

  //The Site ID comes from select value in the generate report tab
  useEffect(() => {
    setsiteId(Number(siteId));
    console.log(siteId);
  }, [siteId]);

  const uploadFile = async () => {
    // S3 Bucket Name
    const S3_BUCKET = "mv-tool-customer-templates";

    // S3 Region
    const REGION = "eu-west-1";

    // S3 Credentials
    AWS.config.update({
      accessKeyId: awsAccessKeyId,
      secretAccessKey: awsSecretAccessKey,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    // Files Parameters

    const params = {
      Bucket: S3_BUCKET,
      Key: `excel_files/${fileName}`,
      Body: selectedFile,
    };

    // Uploading file to s3

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    await upload.then((err, data) => {
      console.log(err);
      // Fille successfully uploaded
      console.log("File uploaded successfully.");
    });
  };

  const sendS3Request = async () => {
    console.log("Sending S3 request...");
    console.log(selectedFile);
    console.log(fileName);

    let raw = JSON.stringify({
      "httpMethod": "POST",
      "path": "/reporting",
      site_id: siteId,
      excel_data: fileName,
      report_name: docxName,
    });

    fetch("https://gqyzj5g642.execute-api.eu-west-1.amazonaws.com/prod/reporting", {
      method: "POST",
      body: raw,
      headers: myHeaders
    })
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => {alert("Error:", JSON.stringify(error)); window.location.reload();});
  };

  async function pollUntilComplete(url, interval, maxAttempts) {
    let attempts = 0;
    let result;

    while (attempts < maxAttempts) {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ filename: docxName }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        result = await response.text();

        if (typeof result === "string") {
          return result;
        }
      } catch (error) {
        return { isError: true, message: error.message };
      }

      attempts++;
      await new Promise((resolve) => setTimeout(resolve, interval));
    }

    return result;
  }

  const triggerPowerAutomate = async () => {
    const result = await pollUntilComplete(url, 5000, 10);

    if (result.isError) {
      setIsGenerating(false);
      alert("Error: " + result.message);
    } else {
      setOneDriveLink(result);
    }
  };

  const mainGenerateFunction = async () => {
    console.log("mainGenerateFunction called");
    setOneDriveLink("");
    setisDone(false);
    

    if (!selectedFile) {
      alert("Please select a file to upload");
      return;
    }
    else {
      setIsGenerating(true);
    }

    try {
      await uploadFile();
    } catch (error) {
      alert("Couldn't Upload File. Please try again.");
      window.location.reload();
      return;
    }

    setTimeout(() => {
      console.log("15 seconds passed - Sending S3 request...");
      sendS3Request();
      setTimeout(() => {
        console.log("170 seconds passed - Triggering Power Automate...");
        triggerPowerAutomate();
      }, 170000);
    }, 15000);
  };

  useEffect(() => {
    setOneDriveLink(oneDriveLink);
    if (oneDriveLink !== "") {
      setisDone(true);
    }
  }, [oneDriveLink]);

  useEffect(() => {
    if (isDone) {
      setIsGenerating(false);
    }
  }, [isDone]);

  useEffect(() => {
    if (isGenerating) {
      setisDone(false);
    }
  }, [isGenerating]);

  //#endregion
//TRIGGER S3 TO CREATE THE DOCX THEN POWER AUTOMATE TO GENERATE PDF (THE GENERATE BUTTON & DOWNLOAD PDF BUTTON)

//#region DOWNLOAD CUSTOMER TEMPLATE FILE (DOWNLOAD TEMPLATE BUTTON ON CUSTOMER TABLE TAB)
  const downloadCustomerTemplate = async (customer) => {
    const s3 = new AWS.S3({
      accessKeyId: awsAccessKeyId,
      secretAccessKey: awsSecretAccessKey,
      region: 'eu-west-1'
    });

    const params = {
      Bucket: 'mv-tool-customer-templates',
      Key: `templates/${customer[16].replace("templates/", "")}`
    };

    s3.getObject(params, (err, data) => {
      if (err) console.error(err);
      
      let blob = new Blob([data.Body], { type: data.ContentType });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = customer[16].replace("templates/", "");
      link.click();
    });
  };

  //#endregion
//DOWNLOAD CUSTOMER TEMPLATE FILE

//#region DOWNLOAD DOCX FILE (DOWNLOAD DOCX BUTTON ON GENERATE REPORT TAB)
const getUploadedFile = async () => {

  const s3 = new AWS.S3({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: 'eu-west-1'
  });

  const params = {
    Bucket: 'mv-tool-customer-templates',
    Key: `reports/${docxName}.docx` // File name you want to download from S3
  };

  s3.getObject(params, (err, data) => {
    if (err) console.error(err);
    
    let blob = new Blob([data.Body], { type: data.ContentType });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = docxName + '.docx';
    link.click();
  });
};
  //#endregion
//DOWNLOAD DOCX FILE

//#region GET COLUMN MAPPINGS
  const [columnMappings, setColumnMappings] = useState(null);

  const fetchColumnMappings = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch("https://gqyzj5g642.execute-api.eu-west-1.amazonaws.com/prod/columns/mappings",requestOptions)
      .then((response) => response.json())
      .then((result) => console.log(JSON.parse(result)) || setColumnMappings(JSON.parse(result)))
      .catch((error) => console.error(error));

    console.log("Fetching column mappings...");
  }

  useEffect(() => {
    if (activeSubTab === "columnMappings" && !columnMappings) {
      fetchColumnMappings();
    }
  }, [activeSubTab, columnMappings]);
//#endregion
//GET COLUMN MAPPINGS

//#region GET COLUMN MAPPINGS INPUTS

  const [columnMappingsInputs, setColumnMappingsInputs] = useState(null);

  const fetchColumnMappingsInputs = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch("https://gqyzj5g642.execute-api.eu-west-1.amazonaws.com/prod/columns",requestOptions)
      .then((response) => response.json())
      .then((result) => console.log(result) || setColumnMappingsInputs(result))
      .catch((error) => console.error(error));

    console.log("Fetching column mappings inputs...");

  }


  useEffect(() => {
    if (activeSubTab === "columnMappings" && !columnMappingsInputs) {
      fetchColumnMappingsInputs();
    }
  }, [activeSubTab, columnMappingsInputs]);

//#endregion
//GET COLUMN MAPPINGS INPUTS

//#region ADD COLUMN MAPPINGS

const [columnInputValues, setColumnInputValues] = useState({});

const handleColumnInputChange = (e, input) => {
  const value = e.target.value.trim();
  setColumnInputValues({
    ...columnInputValues,
    [input]: value,
  });
};

useEffect(() => {
  console.log(columnInputValues);
}, [columnInputValues]);

const addColumnMappings = () => {
  const inputs = mappingInputsRef.current.getElementsByTagName('input');
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].value = '';
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(
      {
        "site_id": siteIdForMappings,
        "column_mappings": columnInputValues
      }
    ),
    redirect: "follow",
  };

  fetch("https://gqyzj5g642.execute-api.eu-west-1.amazonaws.com/prod/columns/mappings", requestOptions)
    .then((response) => response.text())
    .then((result) => alert(JSON.parse(result).message) || fetchColumnMappings())
    .catch((error) => console.error(error));
};

//#endregion
//ADD COLUMN MAPPINGS

//#region UPDATE COLUMN MAPPINGS


const updateColumnMappings = () => {

  // Clear the inputs
  const inputs = mappingInputsRef.current.getElementsByTagName('input');
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].value = '';
  }

  const reversedMappings = Object.entries(columnMappingsForSelectedCustomer[1]).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});

  const updatedColumnMappingsForSelectedCustomer = 
    {
      ...reversedMappings,
      ...columnInputValues
    };
  
  const raw = JSON.stringify({
    "column_mappings": updatedColumnMappingsForSelectedCustomer
  });
  
  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };
  
  fetch(`https://gqyzj5g642.execute-api.eu-west-1.amazonaws.com/prod/columns/mappings?site_id=${siteIdForMappings}`, requestOptions)
    .then((response) => response.text())
    .then((result) => alert(JSON.parse(result).message) || fetchColumnMappings())
    .catch((error) => console.error(error));

    console.log("Updating column mappings... \n" + "Site ID:" + siteIdForMappings + "\nColumn Mappings:" + requestOptions.body);


};


useEffect(() => {
  if (siteIdForMappings && columnMappings && columnMappings.data) {
  setColumnMappingsForSelectedCustomer(columnMappings.data.find((e) => e[0] === siteIdForMappings));
  }
}
, [fetchColumnMappings]);



//#endregion
//UPDATE COLUMN MAPPINGS

//#region DELETE COLUMN MAPPINGS

const deleteColumnMappings = () => {
  const requestOptions = {
    method: "DELETE",
    redirect: "follow",
    headers: myHeaders,
  };

  fetch(`https://gqyzj5g642.execute-api.eu-west-1.amazonaws.com/prod/columns/mappings?site_id=${siteIdForMappings}`, requestOptions)
    .then((response) => response.text())
    .then((result) => alert(JSON.parse(result).message) || fetchColumnMappings())
    .catch((error) => console.error(error));

  console.log("Deleting column mappings...");

};


//#endregion
//DELETE COLUMN MAPPINGS

//#region GET CALCULATION MAPPINGS

  const [calculationMappings, setCalculationMappings] = useState(null);

  const fetchCalculationMappings = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch("https://gqyzj5g642.execute-api.eu-west-1.amazonaws.com/prod/calculations/mappings" , requestOptions)
      .then((response) => response.json())
      .then((result) => console.log(result) || setCalculationMappings(result))
      .catch((error) => console.error(error));

    console.log("Fetching calculation mappings...");
  }

  useEffect(() => {
    if (activeSubTab === "columnMappings" && !calculationMappings) {
      fetchCalculationMappings();
    }
  }, [activeSubTab, calculationMappings]);

  


//#endregion
//GET CALCULATION MAPPINGS

//#region GET CALCULATION MAPPINGS INPUTS

  const [calculationMappingsInputs, setCalculationMappingsInputs] = useState(null);

  const fetchCalculationMappingsInputs = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch("https://gqyzj5g642.execute-api.eu-west-1.amazonaws.com/prod/calculations",requestOptions)
      .then((response) => response.json())
      .then((result) => console.log(result) || setCalculationMappingsInputs(result))
      .catch((error) => console.error(error));

    console.log("Fetching calculation mappings inputs...");

  }

  useEffect(() => {
    if (activeSubTab === "columnMappings" && !calculationMappingsInputs) {
      fetchCalculationMappingsInputs();
    }
  }, [activeSubTab, calculationMappingsInputs]);

  //#endregion
//GET CALCULATION MAPPINGS INPUTS

//#region ADD CALCULATION MAPPINGS
const [calculationInputValues, setCalculationInputValues] = useState({});

const handleCalculationInputChange = (e, input) => {
  const values = e.target.value.split(',').map(value => value.trim());
  setCalculationInputValues({
    ...calculationInputValues,
    [input]: values,
  });
};

useEffect(() => {
  console.log(calculationInputValues);
}, [calculationInputValues]);


const addCalculationMappings = () => {

  const inputs = mappingInputsRef.current.getElementsByTagName('input');
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].value = '';
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(
      {
        "site_id": siteIdForMappings,
        "calculation_mappings": calculationInputValues
      }
    ),
    redirect: "follow",
  };

  fetch("https://gqyzj5g642.execute-api.eu-west-1.amazonaws.com/prod/calculations/mappings", requestOptions)
    .then((response) => response.text())
    .then((result) => alert(JSON.parse(result).message) || fetchCalculationMappings())
    .catch((error) => console.error(error));
};



//#endregion
//ADD CALCULATION MAPPINGS

//#region UPDATE CALCULATION MAPPINGS
const updateCalculationMappings = () => {
  // Clear the inputs
  const inputs = mappingInputsRef.current.getElementsByTagName('input');
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].value = '';
  }

  const updatedCalculationMappingsForSelectedCustomer = 
    {
      ...calculationMappingsForSelectedCustomer[1],
      ...calculationInputValues
    };
  
  const raw = JSON.stringify({
    "calculation_mappings": updatedCalculationMappingsForSelectedCustomer
  });
  
  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };
  
  fetch(`https://gqyzj5g642.execute-api.eu-west-1.amazonaws.com/prod/calculations/mappings?site_id=${siteIdForMappings}`, requestOptions)
    .then((response) => response.text())
    .then((result) => alert(JSON.parse(result).message) || fetchCalculationMappings())
    .catch((error) => console.error(error));

    console.log("Updating calculation mappings... \n" + "Site ID:" + siteIdForMappings + "\nCalculation Mappings:" + requestOptions.body);
};

useEffect(() => {
  if (siteIdForMappings && calculationMappings && calculationMappings.data) {
  setCalculationMappingsForSelectedCustomer(calculationMappings.data.find((e) => e[0] === siteIdForMappings));
  }
}
, [fetchCalculationMappings]);
//#endregion
//UPDATE CALCULATION MAPPINGS

//#region DELETE CALCULATION MAPPINGS
const deleteCalculationMappings = () => {
const requestOptions = {
  method: "DELETE",
  redirect: "follow",
  headers: myHeaders,
};
 
fetch(`https://gqyzj5g642.execute-api.eu-west-1.amazonaws.com/prod/calculations/mappings?site_id=${siteIdForMappings}`, requestOptions)
  .then((response) => response.text())
  .then((result) => alert(JSON.parse(result).message) || fetchCalculationMappings())
  .catch((error) => console.error(error));

console.log("Deleting calculation mappings...");

};
  

//#endregion
//DELETE CALCULATION MAPPINGS

//#region COLUMN & CALCULATION MAPPINGS TABLE AND LIST LOGIC

const [selectedCustomerName, setSelectedCustomerName] = useState(null);

const handleCustomerChange = (event) => {
  setSelectedCustomerName(event.target.value);
}

// Look from customers and get the site id

const [siteIdForMappings, setSiteIdForMappings] = useState(null);

useEffect(() => {
  if (selectedCustomerName) {
    const siteId = customers.data.find((customer) => customer[1] === selectedCustomerName)[0];
    setSiteIdForMappings(siteId);
  }
}
, [selectedCustomerName]);

useEffect(() => {
  if (selectedCustomerName) {
  console.log("selected site id for column mappings:" + siteIdForMappings);
  }
}
, [siteIdForMappings]);

// Get the column mappings list for the selected customer

const [columnMappingsForSelectedCustomer, setColumnMappingsForSelectedCustomer] = useState(null);

useEffect(() => {
  const foundColumnMappings = columnMappings && columnMappings.data && columnMappings.data.find((e) => e[0] === siteIdForMappings);
  setColumnMappingsForSelectedCustomer(foundColumnMappings);
}, [siteIdForMappings]);

useEffect(() => {
  console.log(columnMappingsForSelectedCustomer);
}
, [columnMappingsForSelectedCustomer]);

// Get the calculation mappings list for the selected customer

const [calculationMappingsForSelectedCustomer, setCalculationMappingsForSelectedCustomer] = useState(null);

useEffect(() => {
  const foundCalculationMappings = calculationMappings && calculationMappings.data && calculationMappings.data.find((e) => e[0] === siteIdForMappings);
  setCalculationMappingsForSelectedCustomer(foundCalculationMappings);
}, [siteIdForMappings]);

useEffect(() => {
  console.log(calculationMappingsForSelectedCustomer);
}
, [calculationMappingsForSelectedCustomer]);


// Empty the inputs when the selected customer changes

useEffect(() => {
  setCalculationInputValues({});
  setColumnInputValues({});
  if (siteIdForMappings) {const inputs = mappingInputsRef.current.getElementsByTagName('input');
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].value = '';
  }
  }
  
}, [siteIdForMappings]);


// Calculation Mappings Input Description

const calculationMappingsInputDescription = {
  "F (mHz)": {
    "Required Columns from Mapping": "Fréquence du réseau (mHz)",
    "Description": "Takes single column from column mapping part."
  },
  "Pa PDL (W)": {
    "Required Columns from Mapping": "Puissance active 10sec au PdL (kW) or Puissance temps réel pour la régulation (kW)",
    "Description": "Takes single column from column mapping part but the column can be changed according to site. There are two options."
  },
  "Regul. Site Active": {
    "Required Columns from Mapping": "Etat régulation R1 Ligne 1 (kW), Etat régulation R1 Ligne 2 (kW)",
    "Description": "Takes two columns from column mapping part."
  },
  "Regul. Site Active (Batterie)": {
    "Required Columns from Mapping": "État de régulation effectif vu du site (kW)",
    "Description": "Takes single column from column mapping part."
  },
  "Regul. P1 Active": {
    "Required Columns from Mapping": "Etat régulation R1 Ligne 1 (kW)",
    "Description": "Takes single column from column mapping part."
  },
  "Regul. P2 Active": {
    "Required Columns from Mapping": "Etat régulation R1 Ligne 2 (kW)",
    "Description": "Takes single column from column mapping part."
  },
  "Consigne Delta P R1 (W)": {
    "Required Columns from Mapping": "Consigne Delta P R1 (kW)",
    "Description": "Takes single column from column mapping part."
  },
  "Consigne Gestion SOC Batterie": {
    "Required Columns from Mapping": "Consigne Gestion SOC Batterie (kW)",
    "Description": "Takes single column from column mapping part."
  },
  "Etat SOC Batterie": {
    "Required Columns from Mapping": "État de la charge batterie",
    "Description": "Takes single column from column mapping part."
  },
  "Infra globale": {
    "Required Columns from Mapping": "Infrastructure globale R1 (kW)",
    "Description": "Takes single column from column mapping part."
  },
  "Puissance Engagée": {
    "Required Columns from Mapping": "Puissance Engagée R1 (kW)",
    "Description": "Takes single column from column mapping part."
  },
  "Comm. Transducteur": {
    "Required Columns from Mapping": "Disponibilité R1 Ligne 2 (kW)",
    "Description": "Takes single column from column mapping part."
  },
  "Comm. Supervision site": {
    "Required Columns from Mapping": "Disponibilité R1 Ligne 2 (kW)",
    "Description": "Takes single column from column mapping part."
  },
  "Comm M340": {
    "Required Columns from Mapping": "Disponibilité R1 Ligne 2 (kW)",
    "Description": "Takes single column from column mapping part."
  },
  "Infra locale opérationnelle": {
    "Required Columns from Mapping": "Comm. Transducteur, Comm. Supervision site, Comm M340, Infra globale",
    "Description": "Takes three columns that are being calculated in this step."
  },
  "Delta I1": {
    "Required Columns from Mapping": "Delta I R1 Ligne 1 (kW)",
    "Description": "Takes single column from column mapping part."
  },
  "Delta I2": {
    "Required Columns from Mapping": "Delta I R1 Ligne 2 (kW)",
    "Description": "Takes single column from column mapping part."
  }
}

//#endregion
//COLUMN & CALCULATION MAPPINGS TABLE AND LIST LOGIC


if (accounts && !accounts[0].idTokenClaims.roles.includes(window.location.pathname.split('/')[1]))
  {
    return (
      <div>
        <p>You don't have permission to see this app. </p>
      </div>
    )
  }


  return (
    <div className="mvtoolMain">
      <div className="tabs">
        <div className="tab">
          <button
            className={`tablinks ${activeMainTab === "home" ? "active" : ""}`}
            onClick={() => setActiveMainTab("home")}
          >
            Home
          </button>
        </div>
        <div className="tab">
          <button
            className={`tablinks ${
              activeMainTab === "customerConfig" ? "active" : ""
            }`}
            onClick={() => setActiveMainTab("customerConfig")}
          >
            Customer Configuration
          </button>
        </div>
        <div className="tab">
          <button
            className={`tablinks ${
              activeMainTab === "generateReport" ? "active" : ""
            }`}
            onClick={() => setActiveMainTab("generateReport")}
          >
            Generate Report
          </button>
        </div>
      </div>

      <div className="generalContainer">
        {/* Main Tab: Home */}
        {activeMainTab === "home" && (
          <div className="homeContainer">
            <h1>Welcome to the M&V Tool!</h1>
            <br />
            <p>
              The purpose of M&V Tool is to automate the reporting of our
              customers' R1 performance reports based on RTE's M&V approach.
            </p>
          </div>
        )}

        {/* Main Tab: Customer Configuration */}
        {activeMainTab === "customerConfig" && (
          <div className="customerConfigContainer">
            <div className="customerConfigTabs">
              <button
                className={`customerConfigTablinks ${
                  activeSubTab === "customerTable" ? "active" : ""
                }`}
                onClick={() => setActiveSubTab("customerTable")}
              >
                Customer Table
              </button>
              <button
                className={`customerConfigTablinks ${
                  activeSubTab === "addCustomer" ? "active" : ""
                }`}
                onClick={() => setActiveSubTab("addCustomer")}
              >
                Add Customer
              </button>
              <button
                className={`customerConfigTablinks ${
                  activeSubTab === "columnMappings" ? "active" : ""
                }`}
                onClick={() => setActiveSubTab("columnMappings")}
              >
                Column Mappings
              </button>
            </div>

            {/* Sub Tab: Add Customer */}
            {activeSubTab === "addCustomer" && (
              <div className="addCustomer">
                <div className="customerInfo">
                  <label htmlFor="customer_name">Customer Name</label>
                  <input
                    type="text"
                    className="mvtool-input"
                    id="customer_name"
                  />

                  <label htmlFor="name">Site Name</label>
                  <input type="text" className="mvtool-input" id="name" />

                  <label htmlFor="entity_id">Entity ID</label>
                  <input type="text" className="mvtool-input" id="entity_id" />

                  <label htmlFor="id">ID</label>
                  <input type="number" className="mvtool-input" id="id" />
                </div>

                <div className="powerInfo">
                  <label htmlFor="certificate_power">Certificate Power</label>
                  <input
                    type="number"
                    className="mvtool-input"
                    id="certificate_power"
                  />
                  <select id="power_unit" defaultValue={"Unit"}>
                    <option value="Unit" disabled>
                      {" "}
                      Unit
                    </option>
                    <option value="kW">kW</option>
                    <option value="MW">MW</option>
                  </select>

                  <label htmlFor="level_change_value">Level Change Value</label>
                  <input
                    type="number"
                    className="mvtool-input"
                    id="level_change_value"
                  />
                  <select id="level_change_unit" defaultValue={"Unit"}>
                    <option value="Unit" disabled>
                      Unit
                    </option>
                    <option value="kW">kW</option>
                    <option value="MW">MW</option>
                  </select>

                  <label htmlFor="frequency_value">Frequency Value</label>
                  <input
                    type="number"
                    className="mvtool-input"
                    id="frequency_value"
                  />
                  <select id="frequency_unit" defaultValue={"Unit"}>
                    <option value="Unit" disabled>
                      Unit
                    </option>
                    <option value="Hz">Hz</option>
                    <option value="mHz">mHz</option>
                  </select>

                  <label htmlFor="k_value">K Value</label>
                  <input type="number" className="mvtool-input" id="k_value" />

                  <select id="k_value_unit" defaultValue={"Unit"}>
                    <option value="Unit" disabled>
                      Unit
                    </option>
                    <option value="MW/Hz">MW/Hz</option>
                    <option value="KW/Hz">KW/Hz</option>
                  </select>
                </div>

                <div className="kValue">
                  <label htmlFor="soc_max_mw">SOC Max (MWh)</label>
                  <input type="number" id="soc_max_mw" />
                  <label htmlFor="soc_min_mw">SOC Min (MWh)</label>
                  <input type="number" id="soc_min_mw" />
                  <label htmlFor="useful_energy_mwh">Useful Energy (MWh)</label>
                  <input type="number" id="useful_energy_mwh" />

                  <label htmlFor="template">Customer Template</label>
                  <input
                    type="file"
                    id="template"
                    onChange={handleCustomerTemplateFileChange}
                  />
                </div>

                <div className="customerButtons">
                  <button className="customerButton" onClick={addCustomer}>
                    Save Customer
                  </button>
                </div>
              </div>
            )}

            {/* Update Customer Modal */}
            {isModalOpen && (
              <div className="mv-tool-update-customer-modal">
                {/* Render inputs for each customer field except "template" */}
                {fieldNames &&
                  fieldNames
                    .filter((fieldName) => fieldName !== "template")
                    .map((fieldName, i) => (
                      <div
                        className="mv-tool-update-customer-modal-inputs"
                        key={i}
                      >
                        <label>{fieldName}</label>
                        <input
                          name={fieldName}
                          value={
                            customerUpdateValues[fieldName] ||
                            currentCustomer[i]
                          }
                          onChange={handleUpdateInputChange} // Add an onChange handler
                        />
                      </div>
                    ))}
                {/* Add "template" field */}
                <div className="mv-tool-update-customer-modal-inputs">
                  <label>template</label>
                  <input
                    type="file"
                    name="template"
                    onChange={(event) => {
                      handleCustomerTemplateFileChange(event);
                      handleUpdateInputChange(event);
                    }} // Add an onChange handler for the file input
                  />
                </div>
                <button onClick={updateCustomer}>Update Customer</button>
                <button onClick={closeModal}>Close</button>
              </div>
            )}

            {/* Sub Tab: Column Mappings */}
            {activeSubTab === "columnMappings" && (
              <div className="columnMappings">
                <h2>Customer Name</h2>
                <select id="customerName"  defaultValue="|" onChange={handleCustomerChange}>
                  <option value="|" disabled> Select Customer </option>
                  {customers && customers.data && customers.data.map((customer, index) => (
                      <option key={index} value={customer[1]}>
                        {customer[1]}
                      </option>
                    ))}
                </select>
                <div className="columnMappingsList">
                  {columnMappingsForSelectedCustomer && 
                  <>
                  <h2>Current Column Mappings</h2>
                    <ul>
                      <li><strong>Site ID: </strong> {columnMappingsForSelectedCustomer[0]}</li>
                      {Object.entries(columnMappingsForSelectedCustomer[1]).map(([key, value], i) => (
                        <li key={i}><strong>{value}:</strong> {key}</li>
                      ))}
                    </ul>
                    <button onClick={deleteColumnMappings}>Delete Column Mappings</button>
                    </>
                  }

                  {calculationMappingsForSelectedCustomer &&
                  <>
                  <h2>Current Calculation Mappings</h2>
                    <ul>
                      {Object.entries(calculationMappingsForSelectedCustomer[1]).map(([key, value], i, arr) => (
                        <li key={i}>
                          <strong>{key}:</strong> {Array.isArray(value) ? value.join(', ') : value}{i < arr.length - 1 ? ',' : ''}
                        </li>
                      ))}
                    </ul>
                    <button onClick={deleteCalculationMappings}>Delete Calculation Mappings</button>
                    </>
                  }
                </div>

                <div className="columnAndCalculationMappingInputs" ref={mappingInputsRef}>

                  <div className="columnMappingInputs">
                    {selectedCustomerName && columnMappingsInputs && columnMappingsInputs.data && (
                      <>
                        <h2>{columnMappingsForSelectedCustomer ? 'Update Column Mappings' : 'Add Column Mappings'}</h2>
                        <table>
                        <thead>
                            <tr>
                              <th>Column Name</th>
                              <th>Column Name in Everest</th>
                            </tr>
                          </thead>
                          <tbody>
                            {columnMappingsInputs.data.map((input, index) => (
                              <tr key={index}>
                                <td><label htmlFor={input[1]}>{input[1]}</label></td>
                                <td>
                                  <input 
                                    type="text" 
                                    id={input[1]} 
                                    placeholder={
                                      columnMappingsForSelectedCustomer && columnMappingsForSelectedCustomer[1] 
                                        ? Object.keys(columnMappingsForSelectedCustomer[1]).find(key => columnMappingsForSelectedCustomer[1][key] === input[1])
                                        : ''
                                    } 
                                    onChange={(e) => handleColumnInputChange(e, input[1])}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <button onClick={columnMappingsForSelectedCustomer ? updateColumnMappings : addColumnMappings}>
                          {columnMappingsForSelectedCustomer ? 'Update Column Mappings' : 'Save Column Mappings'}
                        </button>
                      </>
                    )}
                  </div>

                  <div className="calculationMappingInputs">
                    {selectedCustomerName && calculationMappingsInputs && calculationMappingsInputs.data && (
                      <>
                        <h2>{calculationMappingsForSelectedCustomer ? 'Update Calculation Mappings' : 'Add Calculation Mappings'}</h2>
                        <table>
                          <thead>
                            <tr>
                              <th>Calculation Name</th>
                              <th>Required Columns from Mapping</th>
                            </tr>
                          </thead>
                          <tbody>
                            {calculationMappingsInputs.data.map((input, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="tooltip">
                                    <label htmlFor={input[1]}>
                                      {input[1]}
                                    </label>
                                    <span className="tooltiptext">
                                      <p style={{"color":"#ff9900"}} ><strong>{input[1]}</strong></p>
                                      <strong>Required Columns from Mapping:</strong> {calculationMappingsInputDescription[input[1]]['Required Columns from Mapping']}
                                      <br /><br />
                                      <strong>Description:</strong> {calculationMappingsInputDescription[input[1]]['Description']}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <input 
                                    type="text" 
                                    id={input[1]} 
                                    placeholder={calculationMappingsForSelectedCustomer && calculationMappingsForSelectedCustomer[1] ? calculationMappingsForSelectedCustomer[1][input[1]] : ''} 
                                    onChange={(e) => handleCalculationInputChange(e, input[1])}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <button onClick={calculationMappingsForSelectedCustomer ? updateCalculationMappings : addCalculationMappings}>
                          {calculationMappingsForSelectedCustomer ? 'Update Calculation Mappings' : 'Save Calculation Mappings'}
                        </button>
                      </>
                    )}
                  </div>
                
                </div>
                

              </div>
            )}



            {/* Sub Tab: Customer Table */}
            {activeSubTab === "customerTable" && (
              <div className="customerTable">
                <table>
                  <thead>
                    <tr>
                      {customers &&
                        customers.columns &&
                        customers.columns.map((key, index) => (
                          <th key={index}>
                            {key
                              .split("_")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                          </th>
                        ))}
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers &&
                      customers.data &&
                      customers.data.map((customer, index) => (
                        <tr key={index}>
                          {customer.map((value, i) => (
                            <td key={i}>{value}</td>
                          ))}
                          <td>
                            <button onClick={() => deleteCustomer(customer[0])}>
                              Delete
                            </button>
                            <button onClick={() => openModal(customer)}>
                              Update
                            </button>
                            <button
                              onClick={() => downloadCustomerTemplate(customer)}
                            >
                              Download Template
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}

        {/* Main Tab: Generate Report */}
        {activeMainTab === "generateReport" && (
          <div className="generateReportContainer">
            <div className="generateReportInputs">
              <p>Customer Name</p>
              <select
                defaultValue="|"
                onChange={(e) => {
                  const [selectedCustomerName, siteId] =
                    e.target.value.split("|");
                  setCustomerName(selectedCustomerName);
                  setsiteId(siteId);
                  createDocxName(selectedCustomerName);
                }}
              >
                <option value="|" disabled>
                  Select Customer
                </option>
                {customers &&
                  customers.data &&
                  customers.data.map((customer, index) => (
                    <option key={index} value={`${customer[1]}|${customer[0]}`}>
                      {customer[1]}
                    </option>
                  ))}
              </select>
              <p>Customer's Data File</p>
              <label className="custom-file-upload">
                <input
                  type="file"
                  id="mv-tool-customerData"
                  onChange={handleFileChange}
                />
                {fileName}
              </label>
              <button
                id="sendLambdaRequestButton"
                onClick={mainGenerateFunction}
                disabled={!customerName}
              >
                Generate
              </button>
            </div>

            <div className="generatedReport">
              {isGenerating && (
                <div className="mv-tool-modal-content">
                  <img src={img2} alt="loading" />
                  <h2>Generating Report...</h2>
                  <p>Please wait while we generate your link.</p>
                  {/*  put 150 seconds loading bar */}
                  <div className="loader">
                    <div className="bar"></div>
                  </div>
                </div>
              )}

              {oneDriveLink && isDone && (
                <div className="mv-tool-modal-content">
                  <img src={img1} alt="loading" />
                  <p>Report generated successfully!</p>
                  <button
                    className="generatedReportDownloadButton"
                    id="generatedReportDownloadButton-pdf"
                    onClick={() => window.open(oneDriveLink, "_blank")}
                  >
                    Download PDF
                  </button>
                  <button
                    className="generatedReportDownloadButton"
                    id="generatedReportDownloadButton-doc"
                    onClick={getUploadedFile}
                  >
                    Download DOC
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Mvtool;
